








































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref
} from '@nuxtjs/composition-api';
import { getCustomFieldByKey } from '~/helpers/utils';

type HeroBanner = {
  id: number;
  code: 'herobanner';
  number_of_columns: 8 | 12;
  media: {
    id: number;
    media_path: string;
    sequence: number;
  }[];
  data_attributes?: {
    key: string;
    value: string;
  }[];
  urls: [
    {
      title: string;
      url: string;
      overlay_text: string;
      sequence: number;
    }
  ];
  custom_fields: [
    {
      key: 'title';
      value_string: string;
    },
    {
      key: 'description';
      value_string: string;
    },
    {
      key: 'button_text';
      value_string: string;
    },
    {
      key: 'text_color';
      value_select: 'white' | 'black';
    }
  ];
};

export default defineComponent({
  name: 'HeroBanner',
  props: {
    data: {
      type: Object as PropType<HeroBanner>,
      default: () => ({})
    }
  },
  setup(props) {
    const sortedMedia = props.data.urls.sort((a, b) => a.sequence - b.sequence);
    const selectedMediaIndex = ref(0);
    const selectedMedia = computed(
      () => sortedMedia?.[selectedMediaIndex.value] || {}
    );
    let interval;

    const textColor = () => {
      return getCustomFieldByKey(props.data.custom_fields, 'text_color')
        ?.value_select;
    };

    const textSize = computed(() => {
      return props.data.number_of_columns === 12
        ? 'title-large'
        : 'title-medium';
    });

    const dataAttributes = computed(() => {
      const dataAttributesList = props.data.data_attributes ?? [];
      return dataAttributesList.reduce(
        (acc, item) => ({ ...acc, [item.key]: item.value }),
        {}
      );
    });

    const getField = (field: string) => {
      return (
        getCustomFieldByKey(props.data.custom_fields, field)?.value_string ||
        getCustomFieldByKey(props.data.custom_fields, field)?.value_string_area
      );
    };

    const startAutoplay = () => {
      interval = setInterval(() => {
        if (selectedMediaIndex.value + 1 >= sortedMedia.length) {
          return (selectedMediaIndex.value = 0);
        }
        selectedMediaIndex.value++;
      }, 3000);
    };

    const selectmedia = (index) => {
      if (index >= sortedMedia.length) {
        selectedMediaIndex.value = 0;
      } else if (index < 0) {
        selectedMediaIndex.value = sortedMedia.length - 1;
      } else {
        selectedMediaIndex.value = index;
      }

      clearInterval(interval);
      startAutoplay();
    };

    onMounted(() => {
      startAutoplay();

      const hydratedEvent = new CustomEvent('banner-hydrated', {
        detail: dataAttributes.value
      });
      window.dispatchEvent(hydratedEvent);
    });

    onBeforeUnmount(() => clearInterval(interval));

    return {
      sortedMedia,
      selectedMediaIndex,
      selectedMedia,
      textColor,
      textSize,
      dataAttributes,
      getField,
      selectmedia
    };
  }
});
